// @ts-check

import axios from 'axios';
import Vue from 'vue';

/**
 * @typedef {import('../../models/RootState').RootState} RootState
 * @typedef {import('../../models/AdhesionConfig').AdhesionConfig} AdhesionConfig
 * @type {import('vuex').Module<
 *  {
 *    config: AdhesionConfig | null;
 *  },
 *  RootState
 * >}
 */
const module = {
  namespaced: true,

  state() {
    return {
      config: null,
    };
  },

  getters: {
    config: ({ config }) => config,
  },

  actions: {
    async request({ commit }) {
      /** @type {import('axios').AxiosResponse<AdhesionConfig>} */
      const response = await axios({
        method: 'GET',
        url: '/member-data/AdhesionConfig',
      });
      const { data } = response;

      commit('SET_CONFIG', data);

      return data;
    },
  },

  mutations: {
    SET_CONFIG(state, config) {
      Vue.set(state, 'config', config);
    },
  },
};

export default module;
