import Router from 'vue-router';

import routes from './routes';

const router = new Router({
  mode: 'history',
  base: '/app/',
  linkActiveClass: 'is-active',
  scrollBehavior: (/* to, from, savedPosition */) => ({ x: 0, y: 0 }),
  routes,
});

export default router;
