import Vue from 'vue';
import Vuex from 'vuex';

import adhesionConfig from './modules/adhesion-config';
import associations from './modules/associations';
import eventConfig from './modules/event-config';
import globalLayout from './modules/global-layout';
import hospitalGroups from './modules/hospitalGroups';
import hospitals from './modules/hospitals';
import member from './modules/member';
import participants from './modules/participants';
import professions from './modules/professions';
import reg from './modules/reg';
import subscriptionProductConfig from './modules/subscription-product-config';
import subscriptionProduct from './modules/subscription-product';

import i18n from '../bootstraps/i18n';

Vue.use(Vuex);

const debug = process.env.NODE_ENV !== 'production';
const store = new Vuex.Store({
  strict: debug,

  state: {},

  getters: {
    language: () => i18n.locale,
  },

  actions: {},

  mutations: {},

  modules: {
    adhesionConfig,
    associations,
    eventConfig,
    globalLayout,
    hospitalGroups,
    hospitals,
    member,
    participants,
    professions,
    reg,
    subscriptionProductConfig,
    subscriptionProduct,
  },
});

export default store;
