export default {
  // Miscellaneous
  MISC: {
    yes: 'Oui',
    no: 'Non',
    other: 'Autre',
    explain: 'Expliquez...',
    full: 'Complet',
    onSite: 'Sur place',
    byWebcast: 'Par webdiffusion',
    free: 'Gratuit',
    taxesAndTipIncluded: 'taxes et pourboires inclus',
    plusTaxes: '+ taxes.',
    edit: 'Modifier',
    total: 'Total',
    ok: 'OK',
    cancel: 'Annuler',
    precise: 'Précisez',
    submit: 'Envoyer',
    search: 'Rechercher',
    warning: 'Attention',
  },

  COMMON: {
    // Maintenance
    pageDownMaintenance: 'Maintenance en cours',

    errors: {
      inactiveMember: "Il est impossible d'afficher le formulaire car ce compte apparaît comme inactif dans notre système de gestion. Veuillez utiliser un compte actif avant d'essayer à nouveau. Si vous croyez qu'il s'agit d'une erreur, veuillez nous appeler au (418) 658-7679.",
    },
  },

  // Registration section
  REGISTRATION: {
    // Registration closed
    registrationClosedTitle: 'Inscriptions fermées',
    registrationClosedMessage: 'Les inscriptions sont maintenant fermées pour cet événement.',

    // Registration
    registrationTitle: "Formulaire d'inscription",
    registrationBehalfEdit: "Vous êtes entrain de modifier l'inscription de \"{person}\" !!!",
    registrationBehalfCreate: 'Vous êtes entrain de faire une inscription au nom de "{person}" !!!',
    registrationCancelButton: 'Annuler cette inscription',
    registrationByAdminTitle: 'Inscription par administrateur',
    registrationByAdminMessage: "En tant qu'administrateur, vous pouvez inscrire un participant et/ou un conférencier à l'événement. Si une inscription existe déjà pour cette personne à cet événement, il sera possible de la modifier. Pour commencer, veuillez entrer l'email de la personne que vous désirez inscrire à l'événement: {event} :",

    // Days
    daysSelectionTitle: 'Sélectionnez les journées auxquelles vous voulez participer',
    daysErrorMessage: 'Les places pour cette journée sont épuisées',

    // Conferences
    conferencesSelectionTitle: 'Choix des conférences',
    conferenceFullPlzChange: 'Cette conférence affiche complet! Veuillez faire un autre choix!',
    conferenceConfirmPresenceMessage: "J'assisterai à la conférence",

    // Membership
    membershipTitle: "Catégorie d'inscription",
    membershipErrorMessage: "Il existe un problème concernant les catégories d'inscription. Il est impossible de traiter votre demande maintenant. Veuillez contacter le bureau SVP!",

    emailSearch: 'Rechercher par courriel',
    haveAccount: {
      title: 'Avez-vous un compte?',
      list: [
        'Vous êtes-vous déjà inscrit à un de nos évènements?',
        "Êtes-vous membre de l'AMUQ?",
      ],
      text: "Si vous répondez oui à l'une de ces question, vous avez alors un compte avec notre système de gestion. En vous connectant, votre inscription sera plus rapide: vous n'aurez pas à remplir le formulaire au complet.",
      buttons: {
        yes: "Oui j'ai un compte, me connecter",
        no: "Je n'ai pas de compte",
      },
    },
    amuqMemberRequired: "Nos données indiquent que vous devez adhérer à l'AMUQ afin de procéder à l'inscription de cet événement.",

    // DiscountCode
    discountCodeTitle: 'Coupon de rabais',
    discountCodePlaceholder: '',
    discountCodeSuccessMessage: 'Ce coupon de rabais est valide.',
    discountCodeErrorMessage: "Ce coupon de rabais n'est pas valide.",

    // Errors
    errors: {
      invalidConfig: "Il est impossible d'afficher le formulaire car la configuration de l'événement est incomplète.",
      invalidMember: "Il est impossible d'afficher le formulaire car une erreur se trouve dans la fiche du membre.",
      cancelledRegistration: "Il est impossible de modifier cette inscription puisqu'elle a été annulée.",
      plzCorrectErrorsMessage: 'SVP, corrigez les erreurs!',
      errorOccured: 'Une erreur est survenue tandis que le serveur traitait votre demande: {error}',
    },

    cancel: {
      title: "Confirmation d'annulation",
      message: "Êtes-vous certain de vouloir annuler? Cette opération n'enlèvera pas l'inscription. Par contre, les éléments suivants seront supprimés : toutes les activités, les choix de conférence, la catégorie d'inscription, etc. Les frais seront ainsi mis à zéro et l'inscription sera marquée comme inactive. Si une balance existe suite à l'opération, vous devrez rembourser les frais manuellement.",
      buttons: {
        yes: 'Oui, continuer',
        no: 'Stop',
      },
      success: 'Les modifications ont été apportées!',
    },

    checkEmail: {
      emailChangeNotAllowed: 'Vous ne pouvez pas modifier votre adresse électronique en ce moment. Vous pourrez le faire après avoir terminé votre inscription en vous rendant à votre profil.',
      checkEmailMessage: 'Nous avons envoyé une invitation à cette adresse. Veuillez utiliser cette invitation ou contactez le bureau.',
      emailAlreadyUsed: 'Un compte existe déjà avec ce courriel. Veuillez vous connecter à votre compte.',
    },

    validation: {
      missingActivity: "Vous devez corriger les sélections d'activité avant de continuer!",
      missingCollaborator: 'Vous devez cocher la case concernant le guide du collaborateur!',
    },

    registrationSavedMessage: 'Les enregistrements sont faits!',
    changesMadeCreditor: 'Merci! Les modifications concernant votre inscription ont été enregistrées! Nous vous contacterons sous peu car votre compte comporte un solde créditeur.',
    changesMade: 'Les modifications ont été apportées',
    everythingSaved: 'Merci! Le tout a été enregistré!',
  },

  // Subscription section
  SUBSCRIPTION: {
    // Subscription closed
    subscriptionClosedTitle: 'Abonnements fermées',
    subscriptionClosedMessage: 'Les abonnements sont maintenant fermées pour ce produit.',

    // Subscription
    subscriptionTitle: "Formulaire d'abonnement",
    subscriptionBehalfEdit: 'Vous êtes entrain de modifier l\'abonnement de "{person}" !!!',
    subscriptionBehalfCreate: 'Vous êtes entrain de faire un abonnement au nom de "{person}" !!!',
    subscriptionCancelButton: 'Annuler cette abonnement',
    subscriptionByAdminTitle: 'Abonnement par administrateur',
    subscriptionByAdminMessage: `
      En tant qu'administrateur, vous pouvez abonner un membre à un produit d'abonnement.
      Si un abonnement existe déjà pour cette personne, il sera possible de le modifier.
      Pour commencer, veuillez entrer l'email de la personne que vous désirez abonner:`,

    // Membership
    membershipTitle: "Catégorie d'inscription",
    membershipErrorMessage: `
      Il existe un problème concernant les catégories d'inscription.
      Il est impossible de traiter votre demande maintenant.
      Veuillez contacter le bureau SVP!`,

    emailSearch: 'Rechercher par courriel',
    haveAccount: {
      title: 'Avez-vous un compte?',
      list: [
        'Vous êtes-vous déjà inscrit à un de nos évènements?',
        "Êtes-vous membre de l'AMUQ?",
      ],
      text: `
        Si vous avez répondez oui à l'une de ces question, alors vous avez déjà un compte.
        En vous connectant, votre abonnement sera plus rapide: vous n'aurez pas à remplir le formulaire au complet.`,
      buttons: {
        yes: "Oui j'ai un compte, me connecter",
        no: "Je n'ai pas de compte",
      },
    },
    amuqMemberRequired: "Nos données indiquent que vous devez adhérer à l'AMUQ afin de s'abonner à ce produit.",

    // Errors
    errors: {
      invalidConfig: "Il est impossible d'afficher le formulaire car la configuration du produit d'abonnement est incomplète.",
      invalidMember: "Il est impossible d'afficher le formulaire car une erreur se trouve dans la fiche du membre.",
      cancelledSubscription: "Il est impossible de modifier cette inscription puisqu'elle a été annulée.",
      inactiveMember: `
        Il est impossible d'afficher le formulaire car ce compte apparaît comme inactif.
        Veuillez utiliser un compte actif avant d'essayer à nouveau.
        Si vous croyez qu'il s'agit d'une erreur, veuillez nous appeler au (418) 658-7679.`,
      plzCorrectErrorsMessage: 'SVP, corrigez les erreurs!',
      errorOccured: 'Une erreur est survenue tandis que le serveur traitait votre demande: {error}',
    },

    cancel: {
      title: "Confirmation d'annulation",
      message: `
        Êtes-vous certain de vouloir annuler tout changements non sauvegardés?
        Cette opération n'enlèvera pas l'abonnement.
        Les frais seront ainsi mis à zéro et l'abonnement sera marquée comme inactif.
        Si une balance existe suite à l'opération, vous devrez rembourser les frais manuellement.`,
      buttons: {
        yes: 'Oui, continuer',
        no: 'Stop',
      },
      success: 'Les modifications ont été apportées!',
    },

    checkEmail: {
      emailChangeNotAllowed: `
        Vous ne pouvez pas modifier votre courriel pour le moment.
        Vous pourrez le faire après avoir terminé votre abonnement en vous rendant sur votre profil.`,
      checkEmailMessage: `
        Nous avons envoyé une invitation à cette adresse courriel.
        Veuillez utiliser cette invitation ou contactez le bureau.`,
      emailAlreadyUsed: `
        Un compte existe déjà avec ce courriel.
        Veuillez vous connecter à votre compte.`,
    },

    subscriptionSavedMessage: 'Les enregistrements sont faits!',
    changesMadeCreditor: `
      Merci!
      Les modifications concernant votre abonnement ont été enregistrées!
      Nous vous contacterons sous peu car votre compte comporte un solde créditeur.`,
    changesMade: 'Les modifications ont été apportées',
    everythingSaved: 'Merci! Le tout a été enregistré!',
  },

  // Member info section
  MEMBER_INFO: {
    personnalInformationTitle: 'Informations personnelles',
    consentMessage: "J'autorise l'AMUQ à transmettre mon nom et celui de mon établissement d'attache aux partenaires et aux exposants de l'activité de développement professionnel continu à laquelle je viens de m'inscrire, à des fins de promotion ou de sollicitation, s'il y a lieu.",
    affiliationTooltip: "Si vous êtes membre, vous devez vous connecter. Si vous pensez qu'il s'agit d'une erreur, communiquez avec le bureau au 418 658-7679.",
    memberNumPrompt: 'Vous devez entrer votre numéro de membre',
    memberNumPlaceholder: '№ membre',
    professionTitle: 'Profession',
    professionPrompt: 'Sélectionnez votre profession',

    // Patron
    patronQuestionTitle: 'Êtes-vous patron?',
    patronSinceWhenYearMessage: 'Depuis quelle année?',

    // Hospital
    hospitalTitle: "Établissement d'attache",
    hospitalGroupSelectionPlaceholder: "Sélectionnez votre organisation d'attache",
    hospitalSelectionPlaceholder: "Sélectionnez votre établissement d'attache",
    hospitalSelectionOtherPlaceholder: "Précisez votre établissement d'attache...",
  },

  // Proof
  PROOF: {
    proofSent: 'Preuve envoyée!',
    submitButton: 'Télécharger une preuve',
    submitAgainButton: 'Soumettre à nouveau',
    selectedProofMessage: 'Preuve sélectionnée: {proof}',
  },

  // Activity
  ACTIVITY: {
    activityTitle: 'Activités',
    activityFullErrorMessage: 'Le nombre de participants maximum a été atteint! Vous devez modifier votre sélection pour continuer.',
    activityCategoryPrompt: "Sélectionnez votre catégorie d'inscription pour voir le prix",

    activityMoreTickets: [
      'Je désire',
      'billet(s) supplémentaire(s)',
    ],

    activityHasTimeConflictMessage: "Il existe déjà une sélection pour cette heure-là! Pour sélectionner cet élément, veuillez préalablement décocher l'activité sélectionnée.",
    activityCantBeSelected: 'Cette activité est associée à la conférence que vous donnez!',
  },

  // Finances
  FINANCE: {
    financeTitle: 'Sommaire des frais',
    forcePrice: 'Forcer les prix',
    pricesForcedMessage: 'Les prix sont forcés pour cette inscription en date du {date}',

    // Table
    tableHeaderCode: 'Code',
    tableHeaderId: 'ID',
    tableHeaderDescription: 'Description',
    tableHeaderAmount: 'Montant',

    // Config
    config: {
      partialTotalBeforeTaxes: 'Total partiel avant taxes',
      partialTotalIncludingTaxes: 'Total partiel incluant taxes',
    },

    speakerFeesDescription: 'Frais des journées en tant que conférencier',
    speakerRebateDescription: 'Rabais (25%) journée(s) en extra conférencier',
    speakerRebateForConference: 'Rabais conférencier pour conférence: {conference}',
    speakerRebateForActivity: 'Rabais conférencier - activité: {activity}',

    registrationFeesMessage: "Frais d'inscription",
    extraDays: 'Journée(s) en extra',
    extraTickets: '({count} billets supplémentaires)',

    eventRebateFromCoupon: 'Coupon rabais - {code}',

    // Errors
    errors: {
      priceClassConferenceErrorMessage: 'Il est impossible de trouver la classe de prix (conférence)!',
      priceClassRegErrorMessage: 'Il est impossible de trouver la classe de prix (reg)!',
      priceClassErrorDescription: 'Erreur dans la classe de prix',
    },

    registrationFeesConferenceMessage: "Frais d'inscription - Conférence",

    datePromptMessage: 'Entrez la date à laquelle vous désirez que les prix soient effectifs.',
    datePromptPlaceholder: 'Entrez la date',
  },

  // Allergies
  ALLERGIES: {
    allergiesTitle: 'Allergies et autres restrictions alimentaires',
    allergiesPrompt: 'Avez-vous des allergies ou des restrictions alimentaires?',

    // Choices
    choices: {
      none: "Je n'ai aucune intolérance ou allergie",
      vegetarian: 'Je suis végétarien(ne)',
      serious: "J'ai une intolérance ou une restriction alimentaire sérieuse",
      anaphylaxy: "Je suis à risque d'anaphylaxie",
    },

    allergies: 'Allergies',
  },

  SPEAKER: {
    speakerTitle: 'Section conférencier',
    urgencyInQc: "Pratiquez-vous à l'urgence au Québec?",
    twitterAccount: 'Compte Twitter',
    smallBioTitle: 'Mini biographie du parcours professionnel',
    smallBioHelp: 'Ce texte sera utilisé pour vous présenter aux participants lors de l’événement.',
    speakerEtablissementTitle: "Titres et établissements d'attache",
    speakerEtablissementHelp: 'Ce texte sera utilisé pour vous présenter aux participants lors de l’événement.',
    photoTitle: 'Photo',
    feesTitle: 'Honoraires',
    feesHelp: "Vos honoraires seront confirmés par courriel par la coordonnatrice des événements de l'AMUQ. Un chèque d'honoraires vous sera remis par la poste à la suite de l’événement. Aucuns autres frais, résultant de la présence d’un conférencier à cet événement, ne seront défrayés par l’AMUQ, que ce soit le kilométrage, l'essence, le stationnement, le transport, les repas ou autre à moins d’un avis écrit contraire ou d’une entente préétablie. Advenant le remboursement de frais de déplacement, celui-ci se retrouvera sur votre formulaire T4A.",
    feesCheckTo: "Faire le chèque d'honoraires à l'ordre de :",
    feesCheckToHelp: "Votre chèque sera posté à l'adresse stipulée au début du formulaire.",
    feesCompanyTitle: "Si votre chèque d'honoraires est fait à l'ordre d'une compagnie, vous devez nous fournir votre numéro d'entreprise obligatoirement (NE).",
    feesCompanyHelp: "Votre numéro d'entreprise est un numéro de compte composé de neuf chiffres qui sert à identifier votre entreprise auprès du gouvernement fédéral, provincial, et municipal.",
    taxesRegisteredTitle: 'Êtes-vous enregistré auprès des taxes provinciales et fédérales?',
    taxesRegisteredHelp: "Si votre réponse est oui, nous aurons besoin d'une facture de votre part incluant vos numéros de taxes.",
    taxNumberTVQ: '# taxes TVQ',
    taxNumberTPS: '# taxes TPS',
    technicalQuestions: 'Questions techniques',
    conferenceHistory: 'Historique des conférences',
  },

  PICTURE: {
    submitAgain: 'Soumettre à nouveau',
    submitPicture: "Cliquez pour télécharger l'image de votre photo",
    selection: 'Votre sélection: {selection}',
  },

  ACCOUNT: {
    passwordTitle: 'Mot de passe',
    passwordHelp: 'Un compte sera créé afin de vous authentifier dans le futur. Veuillez choisir un mot de passe. Votre mot de passe doit avoir au moins 8 caractères alpha-numériques.',
    passwordChooseText: 'Veuillez choisir un mot de passe:',
    passwordConfirmText: 'Veuillez confirmer ce mot de passe:',
  },

  HOUSING: {
    housingTitle: "Réservation d'hébergement",
    housingHelp: "Je désire faire une réservation d'hébergement:",
    arrival: 'Arrivée',
    nightsCount: 'Nombre de nuits',
    roomType: 'Type de chambre',
    pricePerNight: 'Prix /nuit',
    ccHelp: "Votre réservation d'hôtel est faite avec la carte de crédit qui finit par : {cc}",
    ccNewCard: 'Entrez une nouvelle carte',
    specialRequests: "Requêtes spéaciales pour l'hébergement",
    itemNotAvailable: 'Vous ne pouvez sélectionner cet item! Veuillez faire un autre choix!',

    secondOccupantFirstName: 'Prénom du 2e occupant',
    secondOccupantLastName: 'Nom du 2e occupant',
    commentsDelegate: 'Commentaires',
    commentsAdmin: 'Commentaires (admin)',

    collaborator: 'Collaborateur',
    confirmationNo: 'Numéro de confirmation',
    invoicing: 'Facturation',

    choices: {
      yes: 'OUI - je veux effectuer une réservation',
      no: 'NON - je ne veux pas faire de réservation',
    },

    cc: {
      owner: 'Nom du détenteur de la carte',
      number: 'Numéro de la carte de crédit',
      month: 'Mois',
      year: "L'année",
      ccv: 'CCV',
    },
  },

  ADHESION: {
    emailChangeNotAllowed: 'Vous ne pouvez pas modifier votre adresse électronique en ce moment. Vous pourrez le faire après avoir terminé votre inscription en vous rendant à votre profil.',
    accountAlreadyExist: 'Un compte existe déjà avec ce courriel. Veuillez vous connecter à votre compte.',
    confirmChangeFromSubscription: 'Nos dossiers nous indiquent que vous êtes abonné aux paiements mensuels. Êtes-vous sûr de vouloir changer votre abonnement pour des paiements annuels?',
    correctErrors: 'SVP veuillez corriger les erreurs.',
  },

  PROFILE: {
    confirmation: 'Les enregistrements ont été faits !',
    confirmationWithEmailChange: "Les enregistrements ont été faits. Vous allez recevoir un courriel à l'adresse {email} pour que vous puissiez la confirmer.",
    emailAlreadyUsed: 'Un compte existe déjà avec ce courriel. Veuillez nous contacter au (418) 658-7679.',
    notSent: "Aucun changement n'a été sauvegardé. Si vous avez d'autres changements que vous désirez envoyer, veuillez essayer à nouveau",
    correctErrors: 'SVP veuillez corriger les erreurs.',
    errorOccurred: 'Une erreur est survenue: {error}',
  },

  FIELDS: {
    labels: {
      denomination: 'Dénomination',
      prenom: 'Prénom',
      nom: 'Nom',
      birth: 'Année de naissance',
      nodossier: '№ dossier',
      nomembre: '№ membre',
      start_of_practice: 'Année du début de pratique',
      adresse: 'Adresse',
      ville: 'Ville',
      province: 'Province',
      code_postal: 'Code postal',
      email: 'Courriel',
      tel1: 'Téléphone (résidence)',
      tel2: 'Téléphone (cellulaire)',
      num_pratique: 'Nº de pratique',
      prat_au_quebec: 'Pratique au Québec?',
      centre_hospitalier: "Établissement d'attache",
      membre_amuq_asmuq: 'Membre de ...',
      patron: 'Êtes-vous patron?',
      ruis: 'Veuillez cocher les RUIS dont vous êtes membres:',
      categs: 'Catégories de membre',
      preuve_residence_etudiant: "Preuve d'étudiant ou de résidence (PDF, JPEG, ou PNG)",
      days: 'Je désire participer aux journées suivantes ...',
      confChoice: 'Choix des conférences',
      otherActivities: 'Activités & autres',
      becomeMember: 'Devenir membre',
      feesSummary: 'Sommaire des frais',
      paiement: 'Paiement',
      allergies: 'Allergies et autres restrictions alimentaires',
      iwanthousing: "J'ai besoin d'hébergement",
      housing_arrival_date: 'Arrivée',
      housing_nbr_nights: 'Nombre de nuités',
      housing_room_type: 'Type de chambre',
      housing_special_requests: 'Demandes spéciales',
      housing_sharing: '(Facultatif) Je partagerai ma chambre avec un(e) autre délégué(e). Voici son nom: ',
      housing_payment: "Paiement / réservation d'hôtel",
      name_repeat: 'Nom',
    },

    data: {
      denomination: {
        Dre: 'Dre.',
        Dr: 'Dr.',
        Madame: 'Mme.',
        Monsieur: 'M.',
      },
      membre_amuq_asmuq: {
        m_amuq: "Membre de l'AMUQ",
        m_asmuq: "Membre de l'ASMUQ",
      },
      patron: {
        yes5YearsOrLess: 'Oui (5 ans de pratique ou moins)',
        yesMoreThan5Years: 'Oui (Plus de 5 ans de pratique)',
      },
    },

    validation: {
      iwanthousing: {
        required: "Vous devez indiquer si vous désirez l'hébergement ou non",
      },
      attending: {
        required: 'Ce champ est obligatoire',
      },
      allergiesType: {
        required: 'Merci de répondre à cette question',
      },
      hospitalGroupId: {
        required: 'Vous devez sélectionner une valeur parmi celles proposées!',
      },
      hospitalId: {
        required: "Vous devez sélectionner votre établissement d'attache!",
      },
      hospitalOther: {
        required: 'Veuillez indiquer pourquoi vous avez choisi «autre»!',
      },
      isPatron: {
        required: 'Veuillez indiquer si vous êtes patron',
      },
      start: {
        required: "Veuillez indiquer l'année de début",
      },
      memberNo: {
        required: 'Veuillez indiquer votre numéro de membre',
      },
      professionCode: {
        required: 'Veuillez indiquer votre profession',
      },
      professionExplanation: {
        required: 'Veuillez indiquer votre profession dans le champ prévu ci-dessus',
      },
      subscribe: {
        required: 'Veuillez indiquer si oui ou non vous désirez les paiements mensuels',
      },
      newpassword: {
        required: 'Veuillez indiquer un mot de passe.',
        min: 'Votre mot de passe doit avoir au moins 8 caractères',
      },
      newpasswordconfirm: {
        required: 'Veuillez indiquer le mot de passe à nouveau.',
        confirmed: 'Les mots de passe ne concordent pas',
      },

      confChoices: {
        required: 'Vous devez sélectionner une conférence.',
      },

      tel1: {
        required: 'Le numéro de téléphone est requis.',
      },
    },
  },

  VALIDATION: {
    required: 'Le champ "{label}" est requis.',
    email: 'Le champ "{label}" doit être un courriel valide.',
    phone: 'Le champ "{label}" doit être un numéro de téléphone valide.',
    wordcap: 'Chaque mot du champ "{label}" doit avoir une et une seule majuscule.',
    codepostal: 'Le code postal doit être du format H0H 0H0',
    credit_card: 'Le numéro doit être au format 0000 0000 0000 0000',
    min: 'Le champ "{label}" requiert une longueur minimum de {length} charactères.',
    numeric: 'Le champ "{label}" ne peut que contenir des caractères numériques.',
  },
};

