import Vue from 'vue';
import Buefy from 'buefy';
import VueRouter from 'vue-router';
import * as Sentry from '@sentry/vue';

import i18n from '@/bootstraps/i18n';
import '@/bootstraps/vee-validate';
import '@/lib/money';
import store from '@/store';
import router from '@/router';

import '@/mixins/debugMode';
import '@/mixins/lang';

import App from '@/App.vue';

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    Vue,
    dsn: 'https://0565252921a749ea97ca506db7ce5a98@sentry.io/220355',
  });
}

Vue.use(Buefy, { defaultIconPack: 'fa' });
Vue.use(VueRouter);

const app = new Vue({
  router,
  store,
  render: (h) => h(App),
  i18n,
}).$mount('#app');

export default app;
