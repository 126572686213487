<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
import { detect } from 'detect-browser';

import './assets/bulma.sass';
import './assets/global.scss';

export default {
  name: 'app',
  computed: {
    browser() {
      return detect();
    },
    browserAllowed() {
      return this.browser && (this.browser.name !== 'ie');
    },
  },
  created() {
    if (!this.browserAllowed) {
      if (this.$lang === 'fr') {
        this.$buefy.dialog.alert('Votre navigateur ou la version de votre navigateur n\'est pas supporté. SVP, mettre à jour votre navigateur ou téléchargez Google Chrome.');
      } else {
        this.$buefy.dialog.alert('Your navigator is not supported. Please, upgrade your navigator or use Google Chrome.');
      }
    }
  },
};
</script>

<style lang="scss">
.main-container {
  padding: 20px;
}
</style>
