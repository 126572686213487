// @ts-check

import Vue from 'vue';

Vue.mixin({
  computed: {
    $debug() {
      /** @type {import('vue-router').Route} */
      // @ts-ignore-next-line
      const route = this.$route;

      return !!route.query.debug;
    },
  },
});
