// @ts-check

import axios from 'axios';

/**
 * @typedef {import('@/models/RootState').RootState} RootState
 * @typedef {import('@/models/Association').Association} Association
 * @type {import('vuex').Module<
 *  {
 *    associations: Association[],
 *  },
 *  RootState
 * >}
 */
const module = {
  namespaced: true,

  state: {
    associations: [],
  },

  getters: {
    list: ({ associations }) => associations,
  },

  actions: {
    async fetch({ commit }) {
      /** @type {import('axios').AxiosResponse<Association[]>} */
      const response = await axios({
        method: 'GET',
        url: '/data-api/associations',
      });
      const { data } = response;

      commit('SET_ASSOCIATIONS', data);

      return data;
    },
  },

  mutations: {
    SET_ASSOCIATIONS(state, /** @type {Association[]} */ associations) {
      state.associations.splice(0, state.associations.length);

      for (const association of associations) {
        state.associations.push(association);
      }
    },
  },
};

export default module;
