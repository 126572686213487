// @ts-check

import axios from 'axios';
import Vue from 'vue';

/**
 * @typedef {import('../../models/RootState').RootState} RootState
 * @typedef {{
 *  id: number;
 *  name: string;
 *  status: string | null;
 * }} DidactFormation
 * @type {import('vuex').Module<
 *  {
 *    data: any | null;
 *    didactFormations: DidactFormation[];
 *  },
 *  RootState
 * >}
 */
const module = {
  namespaced: true,

  state: {
    data: null,
    didactFormations: [],
  },

  getters: {
    data: ({ data }) => data,
    didactFormations: ({ didactFormations }) => didactFormations,
  },

  actions: {
    async request({ commit }, { id }) {
      const response = await axios({
        method: 'GET',
        url: '/subscription-product-manager/GetRegistrationData',
        params: {
          id,
          timestamp: Date.now(), // Disable client-side cache
        },
      });
      const { data } = response;

      commit('SET_DATA', data);

      return data;
    },

    async requestDidactFormations({ commit }, { id }) {
      /** @type {import('axios').AxiosResponse<DidactFormation[]>} */
      const response = await axios({
        method: 'GET',
        url: '/subscription-product-registration/DidactFormations',
        params: {
          id,
          timestamp: Date.now(), // Disable client-side cache
        },
      });
      const { data } = response;

      commit('SET_DIDACT_FORMATIONS', data);

      return data;
    },
  },

  mutations: {
    SET_DATA(state, data) {
      Vue.set(state, 'data', data);
    },

    SET_DIDACT_FORMATIONS(state, data) {
      Vue.set(state, 'didactFormations', data);
    },
  },
};

export default module;
