// @ts-check

import axios from 'axios';
import Vue from 'vue';

/**
 * @typedef {import('../../models/RootState').RootState} RootState
 * @typedef {import('../../models/MemberData').MemberData} MemberData
 * @type {import('vuex').Module<
 *  {
 *    data: any;
 *    eventData: null;
 *    subscriptionProductData: null;
 *  },
 *  RootState
 * >}
 */
const module = {
  namespaced: true,

  state: {
    data: null,
    eventData: null,
    subscriptionProductData: null,
  },

  getters: {
    data: ({ data }) => data,
    eventData: ({ eventData }) => eventData,
    subscriptionProductData: ({ subscriptionProductData }) => subscriptionProductData,
  },

  actions: {
    async request({ commit }, payload) {
      const { email = '', inviteToken = '', memberId = 0 } = payload;
      /** @type {import('axios').AxiosResponse<MemberData>} */
      const response = await axios({
        method: 'GET',
        url: '/member-data',
        params: {
          email,
          inviteToken,
          memberId,
        },
      });
      const { data } = response;

      commit('SET_DATA', data);

      return data;
    },
    async requestForEvent({ commit }, payload) {
      const {
        eventId,
        modifyId = 0,
        email = '',
        inviteToken = '',
      } = payload;

      try {
        const response = await axios({
          method: 'GET',
          url: '/member-data/EventRegistrationData',
          params: {
            eventId,
            modifyId,
            email,
            inviteToken,
          },
        });

        commit('SET_EVENT_DATA', response.data);

        return response.data;
      } catch (err) {
        console.log(err);

        return null;
      }
    },
    async requestForSubscription({ commit }, payload) {
      const {
        productId = 0,
        modifyId = 0,
        email = '',
        inviteToken = '',
        memberId = 0,
      } = payload;
      const response = await axios({
        method: 'GET',
        url: '/member-data/SubscriptionRegistrationData',
        params: {
          productId,
          modifyId,
          email,
          inviteToken,
          memberId,
        },
      });

      commit('SET_SUBSCRIPTION_PRODUCT_DATA', response.data);

      return response.data;
    },
  },

  mutations: {
    SET_DATA(state, data) {
      Vue.set(state, 'data', data);
    },
    SET_EVENT_DATA(state, data) {
      Vue.set(state, 'eventData', data);
    },
    SET_SUBSCRIPTION_PRODUCT_DATA(state, data) {
      Vue.set(state, 'subscriptionProductData', data);
    },
  },
};

export default module;
