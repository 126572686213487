// @ts-check

import axios from 'axios';

/**
 * @typedef {import('@/models/RootState').RootState} RootState
 * @typedef {import('@/models/HospitalGroup').HospitalGroup} HospitalGroup
 * @type {import('vuex').Module<
 *  {
 *    groups: HospitalGroup[],
 *  },
 *  RootState
 * >}
 */
const module = {
  namespaced: true,

  state: {
    groups: [],
  },

  getters: {
    list: ({ groups }) => groups,
  },

  actions: {
    async fetch({ commit }) {
      /** @type {import('axios').AxiosResponse<HospitalGroup[]>} */
      const response = await axios({
        method: 'GET',
        url: '/data-api/hospitalGroups',
      });
      const { data } = response;

      commit('SET_GROUPS', data);

      return data;
    },
  },

  mutations: {
    SET_GROUPS(state, /** @type {HospitalGroup[]} */ groups) {
      state.groups.splice(0, state.groups.length);

      for (const group of groups) {
        state.groups.push(group);
      }
    },
  },
};

export default module;
