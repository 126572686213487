import axios from 'axios';
import Vue from 'vue';
import _ from 'lodash';

/**
 * @typedef {import('@/models/RootState').RootState} RootState
 * @type {import('vuex').Module<
 *  {
 *    data: any;
 *    signedParticipations: unknown[];
 *  },
 *  RootState
 * >}
 */
const module = {
  namespaced: true,

  state() {
    return {
      data: null,
    };
  },

  getters: {
    data: ({ data }) => data,
    signedParticipations: ({ data }) => data.signedParticipations,
  },

  actions: {
    async request({ commit }, { type, id }) {
      try {
        const response = await axios({
          method: 'GET',
          url: '/amuq-event-manager/GetParticipants',
          params: {
            type,
            id,
          },
        });
        const { data } = response;

        commit('SET_DATA', data);

        return data;
      } catch (error) {
        console.error(error);

        return null;
      }
    },
    async setSignedParticipation({ commit }, {
      type,
      id,
      memberIds,
      desiredValue,
    }) {
      try {
        await axios({
          method: 'GET',
          url: '/amuq-event-manager/SetParticipations',
          params: {
            type,
            id,
            memberIds: memberIds.join(','),
            action: desiredValue ? 'add' : 'remove',
          },
        });

        commit('SET_SIGNED_STATE', {
          memberIds,
          value: desiredValue,
        });
      } catch (error) {
        console.error(error);
      }

      return null;
    },
  },

  mutations: {
    SET_DATA(state, data) {
      Vue.set(state, 'data', data);
    },
    SET_SIGNED_STATE(state, { memberIds, value }) {
      const { signedParticipations } = state;

      Vue.set(
        state,
        'signedParticipations',
        value
          ? _.union(signedParticipations, memberIds)
          : _.difference(signedParticipations, memberIds),
      );
    },
  },
};

export default module;
