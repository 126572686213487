import Vue from 'vue';

/** @type {(number: number) => string} */
const formatMoney = (number) => {
  const locale = Intl.NumberFormat('fr-CA', {
    minimumFractionDigits: 2,
  });
  return locale.format(number);
};

Vue.mixin({
  filters: {
    money(value) {
      return `${formatMoney(value)} $`;
    },
  },
});

export const round = (/** @type {number} */ number) => Math.round(number * 100) / 100;
export const format = (/** @type {number} */ number) => `${formatMoney(number)} $`;

export default {
  round,
  format,
};
