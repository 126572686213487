import axios from 'axios';
import Vue from 'vue';

// TODO rename file

/**
 * @typedef {import('../../models/RootState').RootState} RootState
 * @type {import('vuex').Module<
 *  {
 *    data: any | null;
 *  },
 *  RootState
 * >}
 */
const module = {
  namespaced: true,

  state() {
    return {
      data: null,
    };
  },

  getters: {
    data: ({ data }) => data,
  },

  actions: {
    async request({ commit }, { registrationId }) {
      const response = await axios({
        method: 'GET',
        url: '/amuq-event-manager/GetRegData',
        params: {
          id: registrationId,
        },
      });
      const { data } = response;

      if (typeof data === 'string' && data.indexOf('Error 500') >= 0) {
        throw new Error('Error 500'); /* silverstripe bug with error 500 that returns 200 */
      }

      commit('SET_DATA', data);

      return data;
    },
  },

  mutations: {
    SET_DATA(state, data) {
      Vue.set(state, 'data', data);
    },
  },
};

export default module;
