// @ts-check

import axios from 'axios';
import Vue from 'vue';

/**
 * @typedef {import('../../models/RootState').RootState} RootState
 * @typedef {import('../../models/subscription-product/Config').Config} SubscriptionProductConfig
 * @type {import('vuex').Module<
 *  {
 *    config: SubscriptionProductConfig | null;
 *  },
 *  RootState
 * >}
 */
const module = {
  namespaced: true,

  state: {
    config: null,
  },

  getters: {
    config: ({ config }) => config,
  },

  actions: {
    async request({ commit }, { productId }) {
      /** @type {import('axios').AxiosResponse<SubscriptionProductConfig>} */
      const response = await axios({
        method: 'GET',
        url: '/subscription-product-manager/GetConfig',
        params: {
          id: productId,
          timestamp: Date.now(), // Disable client-side cache
        },
      });
      const { data } = response;

      commit('SET_CONFIG', data);

      return data;
    },
  },

  mutations: {
    SET_CONFIG(state, config) {
      Vue.set(state, 'config', config);
    },
  },
};

export default module;
