import Vue from 'vue';

/**
 * @typedef {import('../../models/RootState').RootState} RootState
 * @type {import('vuex').Module<
 *  {
 *    title: string;
 *  },
 *  RootState
 * >}
 */
const module = {
  namespaced: true,

  state() {
    return {
      title: '',
    };
  },

  getters: {
    title: ({ title }) => title,
  },

  actions: {
    setTitle({ commit }, title) {
      commit('SET_TITLE', title);
    },
  },

  mutations: {
    SET_TITLE(state, title) {
      Vue.set(state, 'title', title);

      // Update the title
      document.title = title;
    },
  },
};

export default module;
