import Vue from 'vue';
import VueI18n from 'vue-i18n';

import french from './french';
import english from './english';

Vue.use(VueI18n);

const translations = {
  // Français
  fr: french,

  // English
  en: english,
};

export default new VueI18n({
  locale: 'en', // navigator.language.replace(/-[A-Z]{2}$/, ''),
  //locale: navigator.language.replace(/-[A-Z]{2}$/, ''),
  fallbackLocale: 'fr',
  messages: translations,
});
