/** @type {import('vue-router').RouteConfig[]} */
const routes = [
  {
    path: '/register/:eventId',
    name: 'RegForm',
    component: () => import('@/components/registration/Form.vue'),
    props: ({ params }) => ({
      ...params,
      eventId: Number.parseInt(params.eventId, 10),
    }),
  },
  {
    path: '/subscribe/:productId',
    name: 'SubscriptionForm',
    component: () => import('@/components/subscription/SubscriptionForm.vue'),
    props: ({ params }) => ({
      ...params,
      productId: Number.parseInt(params.productId, 10),
    }),
  },
  {
    path: '/join-amuq',
    name: 'AdhesionForm',
    component: () => import('@/components/adhesion/Form.vue'),
  },
  {
    path: '/participants/:type/:id',
    name: 'Participants',
    component: () => import('@/components/participants/Index.vue'),
  },
  {
    path: '/member',
    component: () => import('@/components/member-zone/Index.vue'),
    children: [
      {
        path: 'welcome',
        name: 'member/Welcome',
        component: () => import('@/components/member-zone/welcome/Index.vue'),
      },
      {
        path: 'profile',
        name: 'member/ProfileForm',
        component: () => import('@/components/profile/Form.vue'),
      },
      {
        path: 'membership',
        name: 'member/Membership',
        component: () => import('@/components/membership/Index.vue'),
      },
      {
        path: 'registrations',
        name: 'member/Registrations',
        component: () => import('@/components/member-zone/registrations/Index.vue'),
      },
      {
        path: 'subscriptions',
        name: 'member/Subscriptions',
        component: () => import('@/components/member-zone/subscriptions/Index.vue'),
      },
      {
        path: 'adhesion-receipts',
        name: 'member/AdhesionReceipts',
        component: () => import('@/components/member-zone/adhesion-receipts/Index.vue'),
      },
      {
        path: 'didact-formations',
        name: 'member/DidactFormations',
        component: () => import('@/components/member-zone/didact-formations/Index.vue'),
      },
      {
        path: 'dropbox-upload',
        name: 'DropboxUpload',
        component: () => import('@/components/dropbox/Form.vue'),
      },
      {
        path: '',
        redirect: {
          name: 'member/Welcome',
        },
      },
    ],
  },
  {
    path: '/',
    redirect: ({ query }) => ({
      name: query.id ? 'RegForm' : 'member/Welcome',
      parms: {
        ...query,
        eventId: query.id,
      },
    }),
  },
];

export default routes;
