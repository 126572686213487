import axios from 'axios';
import Vue from 'vue';

/**
 * @typedef {import('../../models/RootState').RootState} RootState
 * @typedef {import('../../models/event/Config').Config} EventConfig
 * @type {import('vuex').Module<
 *  {
 *    config: EventConfig | null;
 *  },
 *  RootState
 * >}
 */
const module = {
  namespaced: true,

  state() {
    return {
      config: null,
    };
  },

  getters: {
    config: ({ config }) => config,
  },

  actions: {
    async request({ commit }, { eventId = 0 }) {
      /** @type {import('axios').AxiosResponse<EventConfig>} */
      const response = await axios({
        method: 'GET',
        url: '/amuq-event-manager/GetConfig',
        params: {
          id: eventId,
          timestamp: Date.now(), // Disable client-side cache
        },
      });
      const { data } = response;

      if (typeof data === 'string' && data.indexOf('Error 500') >= 0) {
        throw new Error('Error 500');
      }

      commit('SET_CONFIG', data);

      return data;
    },
  },

  mutations: {
    SET_CONFIG(state, config) {
      Vue.set(state, 'config', config);
    },
  },
};

export default module;
