export default {
  // Miscellaneous
  MISC: {
    yes: 'Yes',
    no: 'No',
    other: 'Other',
    explain: 'Explain...',
    full: 'Full',
    onSite: 'On site',
    byWebcast: 'by webcast',
    free: 'Free',
    taxesAndTipIncluded: 'taxes and tips included',
    plusTaxes: '+ taxes.',
    edit: 'Edit',
    total: 'Total',
    ok: 'OK',
    cancel: 'Cancel',
    precise: 'Precise',
    submit: 'Submit',
    search: 'Search',
    warning: 'Warning',
  },

  COMMON: {
    // Maintenance
    pageDownMaintenance: 'Page down for maintenance',

    errors: {
      inactiveMember: "We're unable to display the form because this account appears inactive in our system. Please log into an active account before trying again. If you think this is an error, please contact us at (418) 658-7679.",
    },
  },

  // Registration section
  REGISTRATION: {
    // Registration closed
    registrationClosedTitle: 'Registrations closed',
    registrationClosedMessage: 'Registrations are now closed for this event.',

    // Registration
    registrationTitle: 'Registration form',
    registrationBehalfEdit: "You are currently editing \"{person}\"'s registration !!!",
    registrationBehalfCreate: 'You are currently registering on behalf of "{person}" !!!',
    registrationCancelButton: 'Cancel registration',
    registrationByAdminTitle: 'Administrator registration',
    registrationByAdminMessage: "As an administrator, you can register a participant or even a speaker for the event. If a registration for this event already exist for this person, uou'll be able to update it. To begin, please enter the email of the person you want to register to the event: {event} :",

    // Days
    daysSelectionTitle: 'Please select the days you want to participate',
    daysErrorMessage: 'This day is sold out',

    // Conferences
    conferencesSelectionTitle: 'Conferences selection',
    conferenceFullPlzChange: 'This conference is full! Please select another one!',
    conferenceConfirmPresenceMessage: 'I will attend the conference',

    // Membership
    membershipTitle: 'Registration categories',
    membershipErrorMessage: 'There is a problem with the registration categories. We are unable to fullfill your request for now. Please contact us.',

    emailSearch: 'Search by email',
    haveAccount: {
      title: 'Do you have an account?',
      list: [
        'Have you already registered to one of our previous events?',
        'Are you an AMUQ member?',
      ],
      text: "If you responded yes to any of those questions, you do have an account in our system. By logging in, your registration for the event will be easier and faster, you won't have fill the whole form.",
      buttons: {
        yes: 'Yes I already have an account, log me in',
        no: 'I do not have an account',
      },
    },
    amuqMemberRequired: 'Our data indicate that you must be an AMUQ member in order to register for this event.',

    // DiscountCode
    discountCodeTitle: 'Discount code',
    discountCodePlaceholder: '',
    discountCodeSuccessMessage: 'This discount code is valid.',
    discountCodeErrorMessage: 'This discount code is not valid.',

    // Errors
    errors: {
      invalidConfig: "We're unable to display the form because the event configuration is incomplete.",
      invalidMember: "We're unable to display the form because there is an error in the member informations.",
      cancelledRegistration: 'You are unable to edit this registration because it has been cancelled.',
      plzCorrectErrorsMessage: 'Please correct the errors!',
      errorOccured: 'An error occurred: {error}',
    },

    cancel: {
      title: 'Cancellation confirmation',
      message: 'Are you sure you want to cancel? This action will not remove your registration. However, the following elements will be deleted:all the activities, the conference choices, the registration category, etc. The fees will be reset to zero and the registration will be marked as inactive. If a balance exist after the operation, you will need to refund the fees manually.',
      buttons: {
        yes: 'Yes, continue',
        no: 'Stop',
      },
      success: 'Update successfull!',
    },

    checkEmail: {
      emailChangeNotAllowed: 'You are not allowed to change your email right now. You can do it after you finish your registration by heading to your profile.',
      checkEmailMessage: 'We have sent an invitation to this email. Please use this invitation or contact us.',
      emailAlreadyUsed: 'An account already exist for this email. Please log into your account.',
    },

    validation: {
      missingActivity: 'You must correct the activity selection in order to continue!',
      missingCollaborator: 'You must check the collaborator guide!',
    },

    registrationSavedMessage: 'Registration saved!',
    changesMadeCreditor: 'Thanks! Your registration has been updated! We will contact you shortly because your account has a credit balance.',
    changesMade: 'The changes has been saved.',
    everythingSaved: 'Thanks, everything has been saved.',
  },

  // Subscription section
  SUBSCRIPTION: {
    // Subscription closed
    subscriptionClosedTitle: 'Subscriptions closed',
    subscriptionClosedMessage: 'Subscriptions are now closed for this product.',

    // Subscription
    subscriptionTitle: 'Subscription form',
    subscriptionBehalfEdit: 'You are currently editing "{person}"\'s subscription !!!',
    subscriptionBehalfCreate: 'You are currently subscribing on behalf of "{person}" !!!',
    subscriptionCancelButton: 'Cancel subscription',
    subscriptionByAdminTitle: 'Administrator subscription',
    subscriptionByAdminMessage: `
      As an administrator, you can subscribe a participant to a product.
      If a subscription for this product already exist for this person, you'll be able to update it.
      To begin, please enter the email of the person you want to subscribe:`,

    // Membership
    membershipTitle: 'Registration categories',
    membershipErrorMessage: `
      There is a problem with the registration categories.
      We are unable to fullfill your request for now.
      Please contact us.`,

    emailSearch: 'Search by email',
    haveAccount: {
      title: 'Do you have an account?',
      list: [
        'Have you already registered to one of our previous events?',
        'Are you an AMUQ member?',
      ],
      text: `
        If you responded yes to any of those questions, you do have an account in our system.
        By logging in, your subscription will be easier and faster, you won't have to fill the whole form.`,
      buttons: {
        yes: 'Yes I already have an account, log me in',
        no: 'I do not have an account',
      },
    },
    amuqMemberRequired: 'Our data indicate that you must be an AMUQ member in order to subscribe to this product.',

    // Errors
    errors: {
      invalidConfig: "We're unable to display the form because the subscription's product configuration is incomplete.",
      invalidMember: "We're unable to display the form because there is an error in the member informations.",
      cancelledSubscription: 'You are unable to edit this subscription because it has been cancelled.',
      inactiveMember: `
        We're unable to display the form because this account appears inactive in our system.
        Please log into an active account before trying again.
        If you think this is an error, please contact us at (418) 658-7679.`,
      plzCorrectErrorsMessage: 'Please correct the errors!',
      errorOccured: 'An error occurred: {error}',
    },

    cancel: {
      title: 'Cancellation confirmation',
      message: `
        Are you sure you want to cancel any changes you've made?
        This action will not remove your subscription.
        The fees will be reset to zero and the subscription will be marked as inactive.
        If a balance exist after the operation, you will need to refund the fees manually.`,
      buttons: {
        yes: 'Yes, continue',
        no: 'Stop',
      },
      success: 'Update successfull!',
    },

    checkEmail: {
      emailChangeNotAllowed: `
        You are not allowed to change your email right now.
        You can do it after you finish your subscription by heading to your profile.`,
      checkEmailMessage: `
        We have sent an invitation to this email.
        Please use this invitation or contact us.`,
      emailAlreadyUsed: `
        An account already exist for this email.
        Please log into your account.`,
    },

    subscriptionSavedMessage: 'Subscription saved!',
    changesMadeCreditor: `
      Thanks!
      Your subscription has been updated!
      We will contact you shortly because your account has a credit balance.`,
    changesMade: 'The changes has been saved.',
    everythingSaved: 'Thanks, everything has been saved.',
  },

  // Member info section
  MEMBER_INFO: {
    personnalInformationTitle: 'Personnal informations',
    consentMessage: 'I allow the AMUQ to pass on my name and that of my establishment to their partners and exhibitors of the continuous professionnal activity I just subscribed, for promotional or solicitation purposes, if applicatible.',
    affiliationTooltip: 'If you are a member, you must log-in. If you think this is an error, please contact us at 418 658-7679.',
    memberNumPrompt: 'You must enter your member number',
    memberNumPlaceholder: 'Member №',
    professionTitle: 'Profession',
    professionPrompt: 'Select your profession',

    // Patron
    patronQuestionTitle: 'Are you a physician?',
    patronSinceWhenYearMessage: 'Since when?',

    // Hospital
    hospitalTitle: 'Establishment',
    hospitalGroupSelectionPlaceholder: 'Select your organization',
    hospitalSelectionPlaceholder: 'Select your establishment',
    hospitalSelectionOtherPlaceholder: 'Please explain...',
  },

  // Proof
  PROOF: {
    proofSent: 'Proof sent!',
    submitButton: 'Submit a proof',
    submitAgainButton: 'Submit again',
    selectedProofMessage: 'Proof selected: {proof}',
  },

  // Activity
  ACTIVITY: {
    activityTitle: 'Activities',
    activityFullErrorMessage: 'The activity is full, please select another activity.',
    activityCategoryPrompt: 'Select the registration category to see the price',

    activityMoreTickets: [
      'I want',
      'additionnal ticket(s)',
    ],

    activityHasTimeConflictMessage: 'A selection for this time period has already been made. In order to select this element, please uncheck the selected activity.',
    activityCantBeSelected: "This activity is related to the conference you're giving!",
  },

  // Finances
  FINANCE: {
    financeTitle: 'Fees summary',
    forcePrice: 'Force prices',
    pricesForcedMessage: 'Prices are blocked for this registration on this date: {date}',

    // Table
    tableHeaderCode: 'Code',
    tableHeaderId: 'ID',
    tableHeaderDescription: 'Description',
    tableHeaderAmount: 'Amount',

    // Config
    config: {
      partialTotalBeforeTaxes: 'Partial total before taxes',
      partialTotalIncludingTaxes: 'Partial total including taxes',
    },

    speakerFeesDescription: 'Day fees as a speaker',
    speakerRebateDescription: 'Rebate (25%) additionnal day(s) as speaker',
    speakerRebateForConference: 'Speaker rebate for conference: {conference}',
    speakerRebateForActivity: 'Speaker rebate - activity: {activity}',

    registrationFeesMessage: 'Registration fees',
    extraDays: 'Additionnal day(s)',
    extraTickets: '({count} additionnal tickets)',

    eventRebateFromCoupon: 'Discount code - {code}',

    // Errors
    errors: {
      priceClassConferenceErrorMessage: 'No price range has been found (conference)!',
      priceClassRegErrorMessage: 'No price range has been found (reg)!',
      priceClassErrorDescription: 'Error in the price range',
    },

    registrationFeesConferenceMessage: 'Registration fees - Conference',

    datePromptMessage: 'Please input the date at which the prices are effective.',
    datePromptPlaceholder: 'Input the date',
  },

  // Allergies
  ALLERGIES: {
    allergiesTitle: 'Allergies or other found restrictions',
    allergiesPrompt: 'Do you have any allergies or found restrictions?',

    // Choices
    choices: {
      none: 'I have no intolerance nor allergy',
      vegetarian: "I'm vegetarian",
      serious: 'I have an intolerance or a serious food restriction',
      anaphylaxy: "I'm at risk of anaphylaxis",
    },

    allergies: 'Allergies',
  },

  SPEAKER: {
    speakerTitle: 'Speaker section',
    urgencyInQc: 'Do you practice emergency in Québec?',
    twitterAccount: 'Twitter account',
    smallBioTitle: 'Small biography of your professionnal carrer',
    smallBioHelp: 'This text will be used to present yourself to the events participants.',
    speakerEtablissementTitle: 'Establishment title',
    speakerEtablissementHelp: 'This text will be used to present yourself to the events participants.',
    photoTitle: 'Photo',
    feesTitle: 'Fees',
    feesHelp: "Your fees will be confirmed by email from the AMUQ event coordinator. A fees check will be sent to you after the event. No other costs, resulting from the speaker's presence at this event, will be defrayed from the AMUQ, whether it being mileage, gas, parking, transport, meals or any other thing, unless there is a written notice to the contrary or pre-established agreement. In the event of reimbursement of travel expenses, it will be found on your T4A form.",
    feesCheckTo: 'Make the fees check to:',
    feesCheckToHelp: 'Your check will be mailed to the address specified at the beginning of the form.',
    feesCompanyTitle: 'If your fee check is made payable to a company, you must provide us with your business number (NE).',
    feesCompanyHelp: 'Your business number is an account number composed of 9 digits which is used to identify your business to the federal, provincial and municipal governments.',
    taxesRegisteredTitle: 'Are you registed to the provincial and federal taxes?',
    taxesRegisteredHelp: 'If you answer is yes, we will need an invoice from you including your tax numbers.',
    taxNumberTVQ: '# TVQ taxes',
    taxNumberTPS: '# TPS taxes',
    technicalQuestions: 'Technical questions',
    conferenceHistory: 'Conference history',
  },

  PICTURE: {
    submitAgain: 'Submit again',
    submitPicture: 'Click to select your picture',
    selection: 'Your selection: {selection}',
  },

  ACCOUNT: {
    passwordTitle: 'Password',
    passwordHelp: 'An account will be created in order to identify you in the future. Please select a password. Your password must be at least 8 alpha-numeric characters.',
    passwordChooseText: 'Please select a password:',
    passwordConfirmText: 'Please confirm your password:',
  },

  HOUSING: {
    housingTitle: 'Housing booking',
    housingHelp: "I'd like to book a housing:",
    arrival: 'Arrival',
    nightsCount: 'Nights count',
    roomType: 'Room type',
    pricePerNight: 'Price /night',
    ccHelp: 'Your hotel booking is made with a credit card ending with : {cc}',
    ccNewCard: 'Enter a new card',
    specialRequests: 'Special requests for housing',
    itemNotAvailable: 'You cannot select this item, please select another one!',

    secondOccupantFirstName: 'Second occupant first name',
    secondOccupantLastName: 'Second occupant last name',
    commentsDelegate: 'Comments',
    commentsAdmin: 'Comments (admin)',

    collaborator: 'Collaborator',
    confirmationNo: 'Confirmation number',
    invoicing: 'Invoicing',

    choices: {
      yes: 'YES - I wanna make a booking',
      no: "NO - I don't wanna book anything",
    },

    cc: {
      owner: 'Card owner name',
      number: 'Card number',
      month: 'Month',
      year: 'Year',
      ccv: 'CCV',
    },
  },

  ADHESION: {
    accountAlreadyExist: 'An account already exist with this email. Please log into your account.',
    emailChangeNotAllowed: 'You are not allowed to change your email right now. You can do it after you finish your registration by heading to your profile.',
    confirmChangeFromSubscription: 'Our data indicates that you are subscribed to monthly payments. Are you sure you want to change your subscription to annual payments?',
    correctErrors: 'Please correct the errors.',
  },

  PROFILE: {
    confirmation: 'The changes have been saved !',
    confirmationWithEmailChange: 'The changes have been saved. You will receive an email at {email} to confirm it.',
    emailAlreadyUsed: 'An account already exist with this email. Please contact us at (418) 658-7679.',
    notSent: 'No changes have been saved. If you have any other changes you would like to do, send them again.',
    correctErrors: 'Please correct the errors.',
    errorOccurred: 'An error occurred: {error}',
  },

  FIELDS: {
    labels: {
      denomination: 'Title',
      prenom: 'First name',
      nom: 'Last name',
      birth: 'Year of birth',
      nodossier: 'File number',
      nomembre: 'Membership number',
      start_of_practice: 'Start of practice',
      adresse: 'Address',
      ville: 'City',
      province: 'Province',
      code_postal: 'Postal code',
      email: 'Email address',
      tel1: 'Phone number (home)',
      tel2: 'Phone number (cellphone)',
      num_pratique: 'Practice Nº',
      prat_au_quebec: 'Practice in Quebec?',
      centre_hospitalier: 'Home institution',
      membre_amuq_asmuq: 'Member of ...',
      patron: 'Are you a physician?',
      ruis: 'Please select any RUIS you belong to',
      categs: 'Member categories',
      preuve_residence_etudiant: 'Student residency proof (PDF, JPEG or PNG)',
      days: 'I would like to attend the following days ...',
      confChoice: 'Conference choice',
      otherActivities: 'Activities & other',
      becomeMember: 'Become a member',
      feesSummary: 'Fees summary',
      paiement: 'Bill',
      allergies: 'Allergies and dietary restrictions',
      iwanthousing: 'I want housing',
      housing_arrival_date: 'Arrival',
      housing_nbr_nights: 'Number of nights',
      housing_room_type: 'Type of bedroom',
      housing_special_requests: 'Special requests',
      housing_sharing: 'I will be sharing a room with another conference attendee whose name is',
      housing_payment: 'Payment / Hotel reservation',
      name_repeat: 'Name',
    },

    data: {
      denomination: {
        Dre: 'Dr.',
        Dr: 'Dr.',
        Madame: 'Ms.',
        Monsieur: 'Mr.',
      },
      membre_amuq_asmuq: {
        m_amuq: 'AMUQ member',
        m_asmuq: 'ASMUQ member',
      },
      patron: {
        yes5YearsOrLess: 'Yes (5 years of practice or less)',
        yesMoreThan5Years: 'Yes (more than 5 years of practice)',
      },
    },

    validation: {
      iwanthousing: {
        required: 'You must indicate if you need housing or not',
      },
      attending: {
        required: 'This field is required',
      },
      allergiesType: {
        required: 'Please answer this question',
      },
      hospitalGroupId: {
        required: 'You must select a value from the available ones!',
      },
      hospitalId: {
        required: 'You must select your home institution!',
      },
      hospitalOther: {
        required: 'Please indicate why you choosed « other »!',
      },
      isPatron: {
        required: "Please indicate if you're a physician",
      },
      start: {
        required: 'Please indicate the starting year',
      },
      memberNo: {
        required: 'Please indicate your membership number',
      },
      professionCode: {
        required: 'Please indicate your profession',
      },
      professionExplanation: {
        required: 'Please explain your profession inside the field',
      },
      subscribe: {
        required: 'Please indicate if you want monthly bills',
      },
      newpassword: {
        required: 'Please choose a password',
        min: 'Your password must be at least 8 characters long',
      },
      newpasswordconfirm: {
        required: 'Please confirm your password',
        confirmed: 'The passwords do not match',
      },

      confChoices: {
        required: 'You must select a conference.',
      },

      tel1: {
        required: 'The phone number is required.',
      },
    },
  },

  VALIDATION: {
    required: 'The field "{label}" is required.',
    email: 'The field "{label}" must be a valid email.',
    phone: 'The field "{label}" must be a valid phone number.',
    wordcap: 'Each word of the field "{label}" must have one and only uppercase character.',
    codepostal: 'The postal code must follow the H0H 0H0 format',
    credit_card: 'The number must follow the 0000 0000 0000 0000 format',
    min: 'The field "{label}" requires a minimum length of {length} characters.',
    numeric: 'The field "{label}" must only contains numeric values.',
  },
};

