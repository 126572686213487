// @ts-check

import axios from 'axios';

/**
 * @typedef {import('@/models/RootState').RootState} RootState
 * @typedef {import('@/models/Profession').Profession} Profession
 * @type {import('vuex').Module<
 *  {
 *    professions: Profession[],
 *  },
 *  RootState
 * >}
 */
const module = {
  namespaced: true,

  state: {
    professions: [],
  },

  getters: {
    list: ({ professions }) => professions,
  },

  actions: {
    async fetch({ commit }) {
      /** @type {import('axios').AxiosResponse<Profession[]>} */
      const response = await axios({
        method: 'GET',
        url: '/data-api/professions',
      });
      const { data } = response;

      commit('SET_PROFESSIONS', data);

      return data;
    },
  },

  mutations: {
    SET_PROFESSIONS(state, /** @type {Profession[]} */ professions) {
      state.professions.splice(0, state.professions.length);

      for (const profession of professions) {
        state.professions.push(profession);
      }
    },
  },
};

export default module;
