// @ts-check

import axios from 'axios';

/**
 * @typedef {import('@/models/RootState').RootState} RootState
 * @typedef {import('@/models/Hospital').Hospital} Hospital
 * @type {import('vuex').Module<
 *  {
 *    hospitals: Hospital[],
 *  },
 *  RootState
 * >}
 */
const module = {
  namespaced: true,

  state: {
    hospitals: [],
  },

  getters: {
    list: ({ hospitals }) => hospitals,
  },

  actions: {
    async fetch({ commit }) {
      /** @type {import('axios').AxiosResponse<Hospital[]>} */
      const response = await axios({
        method: 'GET',
        url: '/data-api/hospitals',
      });
      const { data } = response;

      commit('SET_HOSPITALS', data);

      return data;
    },
  },

  mutations: {
    SET_HOSPITALS(state, /** @type {Hospital[]} */ hospitals) {
      state.hospitals.splice(0, state.hospitals.length);

      for (const hospital of hospitals) {
        state.hospitals.push(hospital);
      }
    },
  },
};

export default module;
