import i18n from '../bootstraps/i18n';
import french from '../bootstraps/i18n/french';
import english from '../bootstraps/i18n/english';

export const baseFields = [
  'denomination',
  'birth',
  'nodossier',
  'nomembre',
  'prenom',
  'nom',
  'adresse',
  'ville',
  'province',
  'code_postal',
  'email',
  'num_pratique',
  'tel1',
  'tel2',
  'prat_au_quebec',
  'start_of_practice',
  'ruis',
];

export const validation = {
  prenom: 'required|wordcap',
  denomination: 'required',
  nom: 'required|wordcap',
  adresse: 'required',
  ville: 'required',
  province: 'required',
  code_postal: 'required|codepostal',
  email: 'required|email',
  tel1: 'required|phone:country',
  tel2: 'phone:country',
  num_pratique: 'required',
  prat_au_quebec: 'required',
  start_of_practice: 'required',
};

export const fieldsConfig = () => ({
  denomination: {
    label: i18n.t('FIELDS.labels.denomination'),
    hide_label_on_form: false,
    data: {
      '': '',
      Dre: i18n.t('FIELDS.data.denomination.Dre'),
      Dr: i18n.t('FIELDS.data.denomination.Dr'),
      Madame: i18n.t('FIELDS.data.denomination.Madame'),
      Monsieur: i18n.t('FIELDS.data.denomination.Monsieur'),
    },
    grid: 1,
    required: true,
  },
  prenom: {
    label: i18n.t('FIELDS.labels.prenom'),
    grid: 2,
    required: true,
  },
  nom: {
    label: i18n.t('FIELDS.labels.nom'),
    grid: 2,
    required: true,
  },
  birth: {
    label: i18n.t('FIELDS.labels.birth'),
    grid: 1,
    mask: '####',
  },
  nodossier: {
    label: i18n.t('FIELDS.labels.nodossier'),
    grid: 1,
    showif(val) {
      return !!val;
    },
    disabled: true,
  },
  nomembre: {
    label: i18n.t('FIELDS.labels.nomembre'),
    grid: 1,
    showif(val) {
      return !!val;
    },
    disabled: true,
  },
  start_of_practice: {
    label: i18n.t('FIELDS.labels.start_of_practice'),
    mask: '####',
  },
  adresse: {
    label: i18n.t('FIELDS.labels.adresse'),
    grid: 3,
    required: true,
  },
  ville: {
    label: i18n.t('FIELDS.labels.ville'),
    grid: 1,
    required: true,
  },
  province: {
    label: i18n.t('FIELDS.labels.province'),
    grid: 1,
    required: true,
  },
  code_postal: {
    label: i18n.t('FIELDS.labels.code_postal'),
    grid: 1,
    required: true,
    mask: {
      mask: 'JJJJJJJJJJ',
      tokens: {
        J: {
          pattern: /[0-9a-zA-z ]/,
          transform: (v) => v.toLocaleUpperCase(),
        },
      },
    },
  },
  email: {
    label: i18n.t('FIELDS.labels.email'),
    type: 'email',
    grid: 2,
    required: true,
  },
  tel1: {
    label: i18n.t('FIELDS.labels.tel1'),
    type: 'phone',
    grid: 1,
    required: true,
  },
  tel2: {
    label: i18n.t('FIELDS.labels.tel2'),
    type: 'phone',
    grid: 1,
  },
  num_pratique: {
    label: i18n.t('FIELDS.labels.num_pratique'),
    grid: 1,
    required: true,
  },
  prat_au_quebec: {
    label: i18n.t('FIELDS.labels.prat_au_quebec'),
    data: {
      '': '',
      oui: i18n.t('MISC.yes'),
      non: i18n.t('MISC.no'),
    },
    grid: 1,
  },
  centre_hospitalier: {
    label: i18n.t('FIELDS.labels.centre_hospitalier'),
    grid: 1,
  },
  membre_amuq_asmuq: {
    label: i18n.t('FIELDS.labels.membre_amuq_asmuq'),
    type: 'multi-choice',
    data: {
      m_asmuq: i18n.t('FIELDS.data.membre_amuq_asmuq.m_asmuq'),
      m_amuq: i18n.t('FIELDS.data.membre_amuq_asmuq.m_amuq'),
    },
    grid: 1,
  },
  patron: {
    label: i18n.t('FIELDS.labels.patron'),
    data: {
      '': '',
      'Oui (5 ans de pratique ou moins)': i18n.t('FIELDS.data.patron.yes5YearsOrLess'),
      'Oui (Plus de 5 ans de pratique)': i18n.t('FIELDS.data.patron.yesMoreThan5Years'),
      Non: i18n.t('MISC.no'),
    },
    grid: 1,
  },
  ruis: {
    label: i18n.t('FIELDS.labels.ruis'),
    data: {
      laval: 'RUIS Laval',
      mtl: 'RUIS Montréal',
      mcgill: 'RUIS McGill',
      sherbrooke: 'RUIS Sherbrooke',
    },
    type: 'multi-choice',
    disposition: 'horizontal',
    grid: 3,
  },
  categs: {
    label: i18n.t('FIELDS.labels.categs'),
  },
  preuve_residence_etudiant: {
    label: i18n.t('FIELDS.labels.preuve_residence_etudiant'),
    type: 'file',
    eol: true,
  },
  days: {
    label: i18n.t('FIELDS.labels.days'),
    loader: 'LoadDays',
    loader_output: 'LoadDays',
    hide_label_on_form: true,
    type: 'multi-choice',
    eol: true,
    class: 'days-checkboxes',
    reflector: true,
  },
  'conf-choice': {
    label: i18n.t('FIELDS.labels.confChoice'),
    type: 'table',
  },
  'other-activities': {
    label: i18n.t('FIELDS.labels.otherActivities'),
  },
  'become-member': {
    label: i18n.t('FIELDS.labels.becomeMember'),
  },
  'fees-summary': {
    label: i18n.t('FIELDS.labels.feesSummary'),
    type: 'table',
  },
  paiement: {
    type: 'creditcard',
    label: i18n.t('FIELDS.labels.paiement'),
    eol: true,
    required: true,
  },
  allergies: {
    type: 'long-text',
    label: i18n.t('FIELDS.labels.allergies'),
  },
  iwanthousing: {
    label: i18n.t('FIELDS.labels.iwanthousing'),
  },
  housing_arrival_date: {
    label: i18n.t('FIELDS.labels.housing_arrival_date'),
    grid: 1,
  },
  housing_nbr_nights: {
    label: i18n.t('FIELDS.labels.housing_nbr_nights'),
    data: {
      1: 1,
      2: 2,
      3: 3,
      4: 4,
      5: 5,
      6: 6,
      7: 7,
      8: 8,
      9: 9,
      10: 10,
      '': '',
    },
    eol: true,
    grid: 1,
  },
  housing_room_type: {
    label: i18n.t('FIELDS.labels.housing_room_type'),
    loader: 'LoadRoomTypes',
    loader_output: 'LoadRoomTypesOutput',
    hide_label_on_form: true,
  },
  housing_special_requests: {
    label: i18n.t('FIELDS.labels.housing_special_requests'),
    type: 'long-text',
    eol: true,
  },
  housing_sharing: {
    label: i18n.t('FIELDS.labels.housing_sharing'),
  },
  housing_payment: {
    type: 'creditcard',
    label: i18n.t('FIELDS.labels.housing_payment'),
    bypasspaypal: true,
  },
  name_repeat: {
    label: i18n.t('FIELDS.labels.name_repeat'),
  },
});

export const setupValidationMessages = function setupValidationMessages(config, validator, options = {}) {
  const dict = {
    fr: {
      custom: {
        ...french.FIELDS.validation,
      },
    },
    en: {
      custom: {
        ...english.FIELDS.validation,
      },
    },
  };

  const orgs = config.orgs || [];
  if (!options.housingOnly) {
    orgs.forEach((org) => {
      dict.fr.custom[`categ_for_org${org.id}`] = {
        required: "Vous devez spécifier la catégorie d'inscription",
      };
    });
    orgs.forEach((org) => {
      dict.en.custom[`categ_for_org${org.id}`] = {
        required: 'Vous must indicate your registration category',
      };
    });
  }

  (config.days || []).forEach((day) => {
    day.periods.forEach((period) => {
      dict.fr.custom[`conf_choice_for_p${period.id}`] = {
        required: 'Vous devez faire un choix!',
      };
    });
  });

  // validator.localize(dict);
};
