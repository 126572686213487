import Vue from 'vue';
import * as VeeValidate from 'vee-validate';
import {
  required,
  email,
  min,
  min_value,
  confirmed,
  numeric,
} from 'vee-validate/dist/rules';
import fr from 'vee-validate/dist/locale/fr.json';
import en from 'vee-validate/dist/locale/en.json';

import { fieldsConfig } from '@/lib/fields';

import i18n from '../i18n';

/** @typedef {import('vee-validate/dist/types/types').ValidationRule} ValidationRule */
Vue.use(VeeValidate, { inject: false });

/** @type {Record<string, ValidationRule>} */
const validationRules = {
  required,
  numeric,
  email,
  min,
  min_value,
  confirmed,
  phone: {
    validate: (value, [country]) => {
      if (!value) {
        return true;
      }

      if (country === 'ca-usa') {
        return value.replace(/\D/g, '').length === 10;
      }

      return true;
    },
  },
  wordcap: {
    validate: (value) => {
      if (!value) {
        return true;
      }

      const combining = /[\u0300-\u036F]/g;
      const normalized = value.normalize
        ? value.normalize('NFKD').replace(combining, '')
        : value;
      const words = normalized.match(/\b\w+\b/g);

      if (!words || !words.every) {
        return true;
      }

      return words.every((word) => {
        const first = word.substr(0, 1);
        const rest = word.substr(1);

        return first === first.toUpperCase()
          && rest === rest.toLocaleLowerCase();
      });
    },
  },
  codepostal: {
    validate: (value) => {
      if (!value || !value.length || Number.parseInt(value[0], 10) >= 0) {
        return true;
      }

      const match = value.match(/[A-Z]\d[A-Z] \d[A-Z]\d/g);

      return !!(match && match.length);
    },
  },
  credit_card: {
    validate: (value) => /^\d{4}\s?\d{4}\s?\d{4}\s?\d{4}$/.test(String(value).trim()),
  },
};

for (const [ruleName, rule] of Object.entries(validationRules)) {
  VeeValidate.extend(ruleName, {
    ...rule,
    message: (field, params = {}) => {
      const path = `FIELDS.validation.${field}.${ruleName}`;
      const fallbackPath = `VALIDATION.${ruleName}`;

      if (i18n.te(path)) {
        return i18n.t(path);
      } else if (i18n.te(fallbackPath)) {
        return i18n.t(fallbackPath, {
          ...params,
          field,
          label: fieldsConfig()[field]?.label || field,
        });
      } else {
        return rule.message;
      }
    },
  });
}

VeeValidate.localize('fr', fr);
VeeValidate.localize('en', en);
